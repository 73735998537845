import { Box, Divider, Typography } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import NotificationChildrenModal from "../../Generals/Notifications/NotificationChildrenModal.js";
import TitledInput from "../../Generals/Inputs/TitledInput.js";
import TitledSelectorGeneral from "../../Generals/Inputs/TitledSelectorGeneral.js";
import * as masterData from "../../../Master Data/masterData.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import { useDynamicStyles } from "../../Follower/DiscoverScreen/Styles";
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

export function FollowingModal({
  modalData,
  formValues,
  errors,
  handleModalClose,
  handleChange,
}) {
  const Styles = useDynamicStyles();
  const { isXs, isMd } = useBreakpoint();
  const renderModalChildren = () => {
    switch (modalData.modalType) {
      case "follow":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Spaces.Size_3,
              overflow: "auto",
            }}
          >
            <Typography
              sx={{ fontFamily: TextVariables.Body_4, lineHeight: 1.4 }}
            >
              By following this strategy, you agree to automatically copy 
              the trading operations executed by your selected Signal Provider. 
              It’s essential that you understand and agree with the Signal Provider’s strategy, 
              including any Performance Fees they charge, and that you stay informed about the 
              activity in your account. CommuniTraders Software facilitates seamless trade 
              copying based on your pre-configured settings, but please remember that Tradeview SAC 
              is not responsible for any account losses resulting from the Signal Provider’s trading strategy. 
              The responsibility for choosing a provider, monitoring performance, and understanding associated 
              risks rests solely with the user.
            </Typography>
            <Typography
              sx={{ fontFamily: TextVariables.Body_4_Bold, lineHeight: 1.4 }}
            >
              To follow, you can either use the default settings or customize
              the strategy’s settings: copy mode, risk ratio, min. and max. lot
              size and drawdown protection.
            </Typography>
            <Typography
              sx={{ fontFamily: TextVariables.Body_4, lineHeight: 1.4 }}
            >
              Please be cautious when selecting the “default settings” option.
              If you do so, the following configurations will be applied:
            </Typography>
            <Box>
              <Typography
                sx={{ fontFamily: TextVariables.Body_4, lineHeight: 1.4 }}
              >
                &#8226; Copy Mode: Proportional
              </Typography>
              <Typography
                sx={{ fontFamily: TextVariables.Body_4, lineHeight: 1.4 }}
              >
                &#8226; Risk Ratio: 1
              </Typography>
              <Typography
                sx={{ fontFamily: TextVariables.Body_4, lineHeight: 1.4 }}
              >
                &#8226; Min and Max Lot Size: 0
              </Typography>
              <Typography
                sx={{ fontFamily: TextVariables.Body_4, lineHeight: 1.4 }}
              >
                &#8226; Drawdown Protection: No Drawdown
              </Typography>
            </Box>
          </Box>
        );
      case "followCustom":
        return (
          <Box sx={Styles.customFormContainer}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: Spaces.Size_6,
                width: "100%",
              }}
            >
              <Box flex={1}>
                <TitledSelectorGeneral
                  name="copy_mode"
                  title="Copy mode"
                  placeholder={"Please, select a copy mode"}
                  tooltext={
                    <Box sx={Styles.tooltipCopyModeBox}>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          lineHeight: "1.4",
                        }}
                      >
                        The method by which a trade is copied to the follower's
                        account. Two modes are available:{" "}
                      </Typography>
                      <Box sx={Styles.tooltipCopyModeContentBox}>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1_Bold,
                              lineHeight: "1.4",
                            }}
                          >
                            &#8226; Fixed Lots Mode:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              lineHeight: "1.4",
                            }}
                          >
                            which copies trades at a fixed volume equal to the
                            value set in the risk ratio indicator.
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1_Bold,
                              lineHeight: "1.4",
                            }}
                          >
                            &#8226; Proportional Mode:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              lineHeight: "1.4",
                            }}
                          >
                            it adjusts the copied trade volume proportionally
                            based on the follower's and signal provider's
                            equity.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  }
                  handleChange={handleChange}
                  options={masterData.copyMode}
                  error={errors.copy_mode}
                  value={formValues.copy_mode}
                  helperText={
                    errors.copy_mode ? (
                      <Box sx={Styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors,
                          }}
                        >
                          Please, select a copy mode
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                />
              </Box>
              <Box flex={1}>
                <TitledInput
                  name="risk_ratio"
                  value={formValues.risk_ratio}
                  title={
                    formValues.copy_mode === "Proportional"
                      ? "Risk ratio"
                      : "Fixed lots"
                  }
                  tooltext="Determines the lot size for each copied signal in relation with the selected copy mode"
                  type="number"
                  placeholder="Enter your login"
                  handleChange={handleChange}
                  error={errors.risk_ratio}
                  helperText={
                    errors.risk_ratio ? (
                      <Box sx={Styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors.Error_700,
                          }}
                        >
                          Please, please enter a valid risk ratio
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledInput>
              </Box>
            </Box>
            {formValues.copy_mode === "Proportional" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: Spaces.Size_6,
                  width: "100%",
                }}
              >
                <Box flex={1}>
                  <TitledInput
                    name="min_lot_size"
                    value={formValues.min_lot_size}
                    title="Min lot size"
                    type="number"
                    tooltext="Sets the minimum lot size that can be copied. This value must be greater than or equal to 0, with a recommendation of 0.01 for optimal performance. A value of 0 will prevent the software from functioning as intended."
                    placeholder="Enter a value"
                    handleChange={handleChange}
                    error={errors.min_lot_size}
                    helperText={
                      errors.min_lot_size ? (
                        <Box sx={Styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Invalid minimum lot size, must be bigger than maximum lot size
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
                <Box flex={1}>
                  <TitledInput
                    name="max_lot_size"
                    value={formValues.max_lot_size}
                    title="Max lot size"
                    type="number"
                    tooltext="Sets the maximum lot size that can be copied. This value must be greater than or equal to 0, with a recommendation of 1 for optimal performance. A value of 0 will prevent the software from functioning as intended."
                    placeholder="Enter a value"
                    handleChange={handleChange}
                    error={errors.max_lot_size}
                    helperText={
                      errors.max_lot_size ? (
                        <Box sx={Styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, please enter a max. lot size
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
              </Box>
            )}
            <Root>
              <Divider sx={{
                marginTop:'-15px',
                fontFamily: TextVariables.Caption_1,
                fontSize: Spaces.Size_3
              }}>
                Protections
              </Divider>
            </Root>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: Spaces.Size_6,
                width: "100%",
                marginTop:'-25px'
              }}
            >
              <Box flex={1}>
                <TitledSelectorGeneral
                  name="drawdown_mode"
                  title={isMd ? "Drawdown mode" : "DD Mode"}
                  tooltext="The setting that limits the maximum loss your account can tolerate"
                  placeholder={"Please, select a drawdown mode"}
                  handleChange={handleChange}
                  options={masterData.ddProtectionModes}
                  error={errors.drawdown_mode}
                  value={formValues.drawdown_mode}
                  helperText={
                    errors.drawdown_mode ? (
                      <Box sx={Styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors,
                          }}
                        >
                          Please, select a drawdown mode
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledSelectorGeneral>
              </Box>
              {(formValues.drawdown_mode === "InEquity" || formValues.drawdown_mode === "InBalance") && (
                <Box flex={1}>
                  <TitledInput
                    name="max_drawdown"
                    value={formValues.max_drawdown}
                    title={isMd ? "Max. drawdown" : "Max. DD"} 
                    type="number"
                    tooltext="The maximum value of capital loss you are willing to tolerate before stopping your trade."
                    placeholder="Enter your login"
                    handleChange={handleChange}
                    error={errors.max_drawdown}
                    helperText={
                      errors.max_drawdown ? (
                        <Box sx={Styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, please enter a max. drawdown amount
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
              )}
            </Box>
          </Box>
        );
      case "followDefault":
        return (
          <Box sx={Styles.customFormContainer}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: Spaces.Size_6,
                width: "100%",
              }}
            >
              <Box flex={1}>
                <TitledSelectorGeneral
                  name="copy_mode"
                  title="Copy mode"
                  placeholder={"Please, select a copy mode"}
                  disabled
                  tooltext={
                    <Box sx={Styles.tooltipCopyModeBox}>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          lineHeight: "1.4",
                        }}
                      >
                        The method by which a trade is copied to the follower's
                        account. Two modes are available:{" "}
                      </Typography>
                      <Box sx={Styles.tooltipCopyModeContentBox}>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1_Bold,
                              lineHeight: "1.4",
                            }}
                          >
                            &#8226; Fixed Lots Mode:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              lineHeight: "1.4",
                            }}
                          >
                            which copies trades at a fixed volume equal to the
                            value set in the risk ratio indicator.
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1_Bold,
                              lineHeight: "1.4",
                            }}
                          >
                            &#8226; Proportional Mode:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              lineHeight: "1.4",
                            }}
                          >
                            it adjusts the copied trade volume proportionally
                            based on the follower's and signal provider's
                            equity.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  }
                  handleChange={handleChange}
                  options={masterData.copyMode}
                  error={errors.copy_mode}
                  value={formValues.copy_mode}
                  helperText={
                    errors.copy_mode ? (
                      <Box sx={Styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors,
                          }}
                        >
                          Please, select a copy mode
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledSelectorGeneral>
              </Box>
              <Box flex={1}>
                <TitledSelectorGeneral
                  name="drawdown_mode"
                  title="Drawdown mode"
                  tooltext="The setting that limits the maximum loss your account can tolerate"
                  placeholder={"Please, select a drawdown mode"}
                  handleChange={handleChange}
                  disabled
                  options={masterData.ddProtectionModes}
                  error={errors.drawdown_mode}
                  value={formValues.drawdown_mode}
                  helperText={
                    errors.drawdown_mode ? (
                      <Box sx={Styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors,
                          }}
                        >
                          Please, select a drawdown mode
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledSelectorGeneral>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: Spaces.Size_6,
                width: "100%",
              }}
            >
              {formValues.copy_mode !== "" && (
                <Box flex={1}>
                  <TitledInput
                    name="risk_ratio"
                    value={formValues.risk_ratio}
                    title={
                      formValues.copy_mode === "Proportional"
                        ? "Risk ratio"
                        : "Fixed lots"
                    }
                    tooltext="Determines the lot size for each copied signal in relation with the selected copy mode"
                    type="number"
                    disabled
                    placeholder={formValues.copy_mode === "Proportional"
                      ? "Enter a risk ratio value"
                      : "Enter a fixed lots value"}
                    handleChange={handleChange}
                    error={errors.risk_ratio}
                    helperText={
                      errors.risk_ratio ? (
                        <Typography sx={Styles.emptyFieldLabel}>
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          Please, please enter your user
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
              )}
              {(formValues.drawdownmode === "InEquity" || formValues.drawdownmode === "InBalance") && (
                <Box flex={1}>
                  <TitledInput
                    name="max_drawdown"
                    value={formValues.max_drawdown}
                    title="Max. drawdown"
                    type="number"
                    tooltext="The maximum value of capital loss you are willing to tolerate before stopping your trade."
                    placeholder="Enter your login"
                    handleChange={handleChange}
                    disabled
                    error={errors.max_drawdown}
                    helperText={
                      errors.max_drawdown ? (
                        <Typography sx={Styles.emptyFieldLabel}>
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          Please, please enter a max. drawdown amount
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
              )}
            </Box>
            {formValues.copy_mode === "Proportional" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: Spaces.Size_6,
                  width: "100%",
                }}
              >
                <Box flex={1}>
                  <TitledInput
                    name="min_lot_size"
                    value={formValues.min_lot_size}
                    title="Min lot size"
                    type="number"
                    tooltext="Sets the minimum lot size that can be copied. This value must be greater than or equal to 0, with a recommendation of 0.01 for optimal performance. A value of 0 will prevent the software from functioning as intended."
                    placeholder="Enter a value"
                    handleChange={handleChange}
                    disabled
                    error={errors.min_lot_size}
                    helperText={
                      errors.min_lot_size ? (
                        <Typography sx={Styles.emptyFieldLabel}>
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          Please, please enter a min. lot size
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
                <Box flex={1}>
                  <TitledInput
                    name="max_lot_size"
                    value={formValues.max_lot_size}
                    title="Max lot size"
                    type="number"
                    tooltext="Sets the maximum lot size that can be copied. This value must be greater than 0, with a recommendation of 1 for optimal performance. A value of 0 will prevent the software from functioning as intended."
                    placeholder="Enter a value"
                    handleChange={handleChange}
                    disabled
                    error={errors.max_lot_size}
                    helperText={
                      errors.max_lot_size ? (
                        <Typography sx={Styles.emptyFieldLabel}>
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          Please, please enter a max. lot size
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
              </Box>
            )}
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <NotificationChildrenModal
        open={modalData.open}
        title={modalData.title}
        onClose={handleModalClose}
        iconType={modalData.iconType}
        onAcceptLabel={modalData.onAcceptLabel}
        onRefuseLabel={modalData.onRefuseLabel}
        onAccept={modalData.onAccept}
        onRefuse={modalData.onRefuse}
      >
        {renderModalChildren()}
      </NotificationChildrenModal>
    </Box>
  );
}

export default FollowingModal;
