import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useCookies } from "../../Tools/CookieChecker.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import DashboardBackground from "../Backgrounds/DashboardBackground";
import UserServices from "../../services/user.js";
import LoadingSpinner from "../Generals/Loaders/SpinLoader";
import * as styles from "./Styles.js";
import {
  Box,
  Link,
  Button,
  Popover,
  IconButton,
  Typography,
} from "@mui/material";
import LanguageSelector from "../Generals/Selectors/languageSelector.js";
import logo_communitraders from "../../assets/logos/Logo-communitraders.svg";
import SwitchBasic from "../Generals/Switches/Switch";
import NotificationModal from "../Generals/Notifications/NotificationModal.js";
import signalService from "../../services/signalProvider";
import InfoChipRisk from "../Generals/Cards/InfoChipRisk";
import { useBreakpoint, StatusProvider } from "../../config/BreakpointContext.js";
import MenuIcon from "@mui/icons-material/Menu"; // Ícono de menú
import CloseIcon from "@mui/icons-material/Close";


const NavbarAndSidebar = (props) => {
  //#region "Instantiation of States"
  const { isXs, isMd } = useBreakpoint();
  const [userName, setUserName] = useState("");
  const [avatarLetter, setAvatarLetter] = useState("");
  const [avatarColor, setAvatarColor] = useState("");
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [openProfileModal, setopenProfileModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [strategyInformation, setStrategyInformation] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    name: "",
    login: 0,
  });
  const [accountStatus, setAccountStatus] = useState([
    {
      name: "PyG",
      value: 0,
    },
    {
      name: "Margin",
      value: 0,
    },
    {
      name: "Free margin",
      value: 0,
    },
    {
      name: "Balance",
      value: 0,
    },
    { 
      name: "Credit", 
      value: 0},
    {
      name: "Equity",
      value: 0,
    },
    {
      name: "Risk",
      value: 0,
    },
  ]);
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onContinueLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
    onContinue: undefined,
  });
  const [openSidebar, setOpenSidebar] = useState(false);
  const statusValue = useMemo(
    () => ({
      accountStatus,
    }),
    [accountStatus]
  );
  //#endregion

  //#region "Declaration of Normal Variables or Constants"

  const open = Boolean(anchorEl);
  const openProfile = Boolean(openProfileModal);
  const id = open ? "simple-popover" : undefined;

  //#endregion

  //#region "Handlers"
  const handleProfileClick = (event) => {
    setopenProfileModal(event.currentTarget);
  };

  const handleSetVisibility = (event) => {
    setModalProps({
      open: true,
      iconType: "warning",
      title: !event.target.checked
        ? "Are you sure you want to disable visibility?"
        : "Are you sure you want to enable visibility?",
      message: !event.target.checked
        ? "If you disable visibility, followers will not be able to see your strategy and start following it."
        : "If you enable visibility, followers will be able to see your strategy and start following it.",
      onAcceptLabel: "Accept",
      onRefuseLabel: "Cancel",
      onAccept: () => handleConfirmChangeVisibility(event),
      onRefuse: () => handleCloseNotification(),
    });
  };

  const handleSwitchProfile = (event) => {
    setModalProps({
      open: true,
      iconType: "warning",
      title: "Are you sure you want to be a follower?",
      message: 
        "If you switch profile to follower, followers will not be able to see your strategy.",
      onAcceptLabel: "Accept",
      onRefuseLabel: "Cancel",
      onAccept: () => RequestStopBeingSignal(),
      onRefuse: () => handleCloseNotification(),
    });
  };

  const handleConfirmChangeVisibility = (event) => {
    setVisibility(event.target.checked);
    const signalInfo = {
      ...strategyInformation,
      visibility: event.target.checked,
    };
    UpdateUserStrategy(signalInfo);
  };

  const handleProfileClose = () => {
    setopenProfileModal(null);
  };

  const handleHome = () => {
    navigate("/SignalProvider/Profile");
  };

  const handleLogout = () => {
    navigate("/login");
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  const handleOpenSideBar = () => {
    setOpenSidebar(!openSidebar);
  };

  //#endregion

  //#region "useEffects"
  useEffect(() => {
    if (profileInfo.name) {
      setAvatarLetter(profileInfo.name.split(" ")[0][0]);
      setAvatarColor(stringToColor(profileInfo.name));
    }
  }, [profileInfo.name]);
  useEffect(() => {
    RequestDashboardData();
    RequestInformationData();
  }, []);
  //#endregion

  //#region "Requests"
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  const getButtonColor = (menuItem) => {
    if (props.sidebarOption === menuItem) {
      return {
        backgroundColor: Colors.Secondary_50,
        color: "#15202A",
      };
    }
    return {};
  };
  const RequestDashboardData = async () => {
    try {
      const response = await UserServices.getStatus();
      if (response.data != null) {
        setAccountStatus([
          {
            name: "P&L",
            value: response.data.data.pnl,
          },
          {
            name: "Margin",
            value: response.data.data.margin,
          },
          {
            name: "Free margin",
            value: response.data.data.free_margin,
          },
          {
            name: "Balance",
            value: response.data.data.balance,
          },
          { 
            name: "Credit",
            value: response.data.data.credit
          },
          {
            name: "Equity",
            value: response.data.data.equity,
          },
          {
            name: "Risk",
            value: response.data.data.risk,
          },
        ]);
        setProfileInfo({
          name: response.data.data.name,
          login: response.data.data.account,
        });
      } else {
      }
    } catch (error) {
      console.log(error);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "An error has occurred while loading the status information. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
  };

  const RequestInformationData = async () => {
    try {
      const response = await UserServices.getInformation();
      if (response.data != null) {
        setStrategyInformation(response.data.data);
        setVisibility(response.data.data.visibility);
      } else {
      }
    } catch (error) {
      console.log(error);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "An error has occurred while loading the status information. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
  };

  const RequestStopBeingSignal = async () => {
    try {
      setLoading(true);
      const response = await signalService.deleteMaster();
      if (response.data != null) {
        setLoading(false);
        navigate("/login");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (
        error.response.data.message ===
        "cannot be removed as a signal provider. close the operations you have in progress"
      ) {
        setModalProps({
          open: true,
          iconType: "error",
          title: "Operation not permitted",
          message:
            "The account cannot be stopped by the signal provider as it has open trades. Please close the trades and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      } else {
        setModalProps({
          open: true,
          iconType: "error",
          title: "Error deleting signal",
          message:
            "Error when ceasing to be a signal provider, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
    setLoading(false);
  };

  const UpdateUserStrategy = async (signalInfo) => {
    try {
      setLoading(true);
      const response = await signalService.editMaster({
        "visibility": signalInfo.visibility
      });
      if (response.data != null) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setVisibility(!visibility);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error setting visibility",
        message:
          "An unexpected error has occurred, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };
  //#endregion

  //#region "HTML"
  return (
    <StatusProvider value={statusValue}>
      <Box sx={{ height: "100%", margin: "0", padding: "0" }}>
        {loading ? <LoadingSpinner /> : <></>}
        <DashboardBackground></DashboardBackground>
        <NotificationModal
          open={modalProps.open}
          onClose={handleCloseNotification}
          iconType={modalProps.iconType}
          title={modalProps.title}
          message={modalProps.message}
          onAcceptLabel={modalProps.onAcceptLabel}
          onAccept={modalProps.onAccept}
          onRefuseLabel={modalProps.onRefuseLabel}
          onRefuse={modalProps.onRefuse}
          onContinueLabel={modalProps.onContinueLabel}
          onContinue={modalProps.onContinue}
        />
        <Box sx={styles.navbar}>
          <IconButton
            sx={{
              backgroundColor: "transparent",
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={(e) => handleHome()}
          >
            <img src={logo_communitraders} alt="Tradeview" width={"200"} />
          </IconButton>
          {isMd && (
            <Box sx={styles.statusBox}>
              {accountStatus.map((item, index) => (
                <Box sx={styles.statusItem} key={index}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      lineHeight: "1.4",
                      color: Colors.Secondary_700,
                    }}
                  >
                    {item.name}
                  </Typography>
                  {item.name === "Risk" && <InfoChipRisk text={item.value} />}
                  {item.name !== "Risk" && (
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Body_3_Bold,
                        lineHeight: "1.4",
                      }}
                    >
                      {item.value}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}
          {isMd && (
            <Box sx={styles.perfilContainer}>
              <LanguageSelector></LanguageSelector>
              <Button sx={styles.profileBox} onClick={handleProfileClick}>
                <Avatar
                  sx={{
                    alt: userName,
                    bgcolor: avatarColor,
                    width: 20,
                    height: 20,
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  {avatarLetter}
                </Avatar>
                <Box sx={styles.nameBox}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      lineHeight: "1",
                    }}
                  >
                    Hello
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                      lineHeight: "1",
                    }}
                  >
                    {profileInfo.name.split(" ")[0]}
                  </Typography>
                </Box>
                <span className="material-symbols-outlined">expand_more</span>
              </Button>
              <Popover
                id={id}
                open={openProfile}
                anchorEl={openProfileModal}
                onClose={handleProfileClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  sx: {
                    maxHeight: "416px", // Altura máxima del popover para evitar expandir demasiado
                    overflowY: "hidden", // Habilita el scroll si el contenido es grande
                  },
                }}
              >
                <Box sx={styles.modalProfile}>
                  <Box sx={styles.modalProfileTitle}>
                    <Avatar
                      sx={{
                        alt: userName,
                        bgcolor: avatarColor,
                        width: 20,
                        height: 20,
                        fontSize: 15,
                        padding: 2,
                      }}
                    >
                      {avatarLetter}
                    </Avatar>
                    <Box sx={styles.nameBox}>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Body_3_Bold,

                          lineHeight: "1",
                        }}
                      >
                        {profileInfo.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          lineHeight: "1",
                        }}
                      >
                        {profileInfo.login}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      onClick={(e) => handleSwitchProfile()}
                      sx={styles.profileSelector}
                    >
                      <span
                        className="material-symbols-outlined"
                        style={{ color: Colors.Secondary_900 }}
                      >
                        swap_horiz
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          width: "100%",
                        }}
                      >
                        Switch profile
                      </Typography>
                    </Button>
                    {/* <Button sx={styles.profileSelector}>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: Colors.Secondary_900 }}
                      >
                        settings
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          width: "100%",
                        }}
                      >
                        Settings
                      </Typography>
                    </Button> */}
                    <Button sx={styles.profileSelector} onClick={handleLogout}>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: Colors.Secondary_900 }}
                      >
                        logout
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          width: "100%",
                        }}
                      >
                        Log Out
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
          )}
          {isXs && (
            <IconButton onClick={handleOpenSideBar}>
              <MenuIcon />
            </IconButton>
          )}
        </Box>
        {isMd && (
          <Box sx={styles.sideBar}>
            <Box sx={styles.infoSidebar}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: TextVariables.Body_4_Bold,
                  lineHeight: 1,
                }}
              >
                Signal provider
              </Typography>
              <Box sx={styles.sidebarNameContainer}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Caption_1,
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1,
                  }}
                >
                  {profileInfo.name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Caption_1,
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1,
                  }}
                >
                  {profileInfo.login}
                </Typography>
              </Box>
              <Box sx={styles.visibilityBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: "1",
                  }}
                >
                  Visibility
                </Typography>
                <SwitchBasic
                  value={visibility}
                  handleChange={handleSetVisibility}
                />
              </Box>
            </Box>
            <Box sx={styles.nav}>
              <Link
                sx={styles.tab}
                style={getButtonColor("SignalProfile")}
                component={RouterLink}
                to="/SignalProvider/Profile"
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "20px" }}
                >
                  explore
                </span>
                My profile
              </Link>
              <Link
                sx={styles.tab}
                style={getButtonColor("Followers")}
                component={RouterLink}
                to="/SignalProvider/Followers"
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "20px" }}
                >
                  person_add
                </span>
                Followers
              </Link>
              <Link
                sx={styles.tab}
                style={getButtonColor("SignalOrders")}
                component={RouterLink}
                to="/SignalProvider/Orders"
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "20px" }}
                >
                  assignment
                </span>
                Orders
              </Link>
            </Box>
          </Box>
        )}
        {isXs && openSidebar && (
          <Box sx={styles.smallSidebarContainer}>
            <Box sx={styles.EmptySidebar}></Box>
            <Box sx={styles.sideBarSmall}>
              <IconButton
                sx={styles.closeIconContainer}
                onClick={handleOpenSideBar}
              >
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
              <Box sx={styles.infoSidebarSmall}>
                <Box sx={styles.profileBoxSmall}>
                  <Avatar
                    sx={{
                      alt: userName,
                      bgcolor: avatarColor,
                      width: 20,
                      height: 20,
                      fontSize: 15,
                      padding: 2,
                    }}
                  >
                    {avatarLetter}
                  </Avatar>
                  <Box sx={styles.nameBox}>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1,
                        lineHeight: "1",
                        color: "white",
                      }}
                    >
                      Hello {profileInfo.name.split(" ")[0]}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Body_3_Bold,
                        lineHeight: "1",
                        color: "white",
                      }}
                    >
                      {profileInfo.login}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={styles.infoSidebarSmall}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Signal provider
                </Typography>
                <Box sx={styles.sidebarNameContainer}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: 1,
                    }}
                  >
                    {profileInfo.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: 1,
                    }}
                  >
                    {profileInfo.login}
                  </Typography>
                </Box>
                <Box sx={styles.visibilityBox}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_4_Bold,
                      lineHeight: "1",
                    }}
                  >
                    Visibility
                  </Typography>
                  <SwitchBasic
                    value={visibility}
                    handleChange={handleSetVisibility}
                  />
                </Box>
              </Box>

              <Box sx={styles.nav}>
                <Link
                  sx={styles.tab}
                  style={getButtonColor("SignalProfile")}
                  component={RouterLink}
                  to="/SignalProvider/Profile"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    explore
                  </span>
                  My profile
                </Link>
                <Link
                  sx={styles.tab}
                  style={getButtonColor("Followers")}
                  component={RouterLink}
                  to="/SignalProvider/Followers"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    person_add
                  </span>
                  Followers
                </Link>
                <Link
                  sx={styles.tab}
                  style={getButtonColor("SignalOrders")}
                  component={RouterLink}
                  to="/SignalProvider/Orders"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    assignment
                  </span>
                  Orders
                </Link>
              </Box>
              <Box sx={styles.nav}>
                <Link sx={styles.tab} onClick={(e) => handleSwitchProfile()}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    swap_horiz
                  </span>
                  Switch profile
                </Link>
                <Link sx={styles.tab} onClick={handleLogout}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "20px" }}
                  >
                    logout
                  </span>
                  Log out
                </Link>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={isMd === true ? styles.content : styles.contentSmall}>
          {props.children}
        </Box>
      </Box>
    </StatusProvider>
  );
  //#endregion
};

export default NavbarAndSidebar;
